.Button {
  font-size: 0.8rem;
  font-weight: 700;
  color: #fff;
  padding: .6875rem 1.25rem;
  line-height: 1.5;
  text-transform: uppercase;
  border: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background: $dnvgl-lightblue;
  text-decoration: none;

  img {
    padding-left: 1.2rem;
  }

  &--back {
    img {
      padding-left: 0;
      padding-right: 1.2rem;
    }
  }
}