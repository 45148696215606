.BreakoutRoom {
  background-image: url(../../assets/images/dnvffts_2_1920_g.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;

  &__grid {
    @include grid-desktop;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
  }

  &__title {
    grid-column: 1 / span 12;
    grid-row: 1;
    margin: 0;
  }

  &__info {
    grid-column: 1 / span 12;
    grid-row: 2;
    color: #fff;
    h2, p {
      white-space: pre-line;
    }
    h2 {
      font-size: 1.25rem;
      line-height: 1.1;
      padding-bottom: 1.75rem;
      margin: 0;
    }
  }

  &__room {
    grid-row: 3;
    grid-column: 1 / span 12;
    &--with-chat {
      grid-column: 1 / span 8;
    }
  }

  &__chat {
    grid-row: 3;
    grid-column: 9 / span 4;
  }
}