.Home {
  background-image: url(../../assets/images/home_page_23.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
  overflow-x: hidden;

  &__grid {
    @include grid-desktop;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    padding-bottom: 0;

    &__container {
      grid-column: 1 / span 12;
      grid-row: 1;

      &__imageHeader {
        text-align: center;
        img {
          display: block;
          max-width: 100%;
          margin-top: -35px;
        }
        &-subheadline {
          padding: 0px 20%;
          margin-bottom: 20px;
          margin-top: -8px;
        }
      }

    }

    &__left {
      grid-column: 1 / span 8;
      grid-row: 1;
    }
    
    &__right {
      position: relative;
      z-index: 20;
      grid-column: 9 / span 4;
      grid-row: 1;
    }

    &__quick-links {
      display: grid;
      grid-row: 2;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 20px 20px;
      margin-bottom: 55px;
    }

    &__highlights {
      display: grid;
      grid-row: 1;
      grid-column: 9 / span 4;
      grid-row: 1;
      gap: 0px 0px;
      position: relative;
      z-index: 10;

      &::before {
        content: "";
        position: absolute;
        left: -100vw;
        top: -35px;
        width: 200vw;
        height: 100%;
        padding: 0;
        padding-bottom: 4rem;
        margin: 0;
        background:$dnvgl-lightblue;
        opacity: 0.25;
        z-index: 0;
        pointer-events: none;
      }
      
      h2 {
        font-family: $dnv-display-medium;
        color: $white;
        font-size: 1.2rem;
        padding-bottom: 1.4rem;
        text-transform: uppercase;
      }

    }

    .Home__intro {
      @include body-copy;
      background: #91ffb4;
      color: #0f204b;
      font-size: 1.2rem;
      line-height: 1.7rem;
      padding: 1rem;
      margin-bottom: 2rem;

      a, a:visited {
        text-decoration: none;
        color: $dnvgl-mediumblue;
        font-size: inherit;
      }
    }
    
    h1 {
      padding-bottom: 0; 
      margin-bottom: 0;
    }
  }
}