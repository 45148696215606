.ComingUp {
  background: rgba(255,255,255,1);
  width: 100%;
  height: auto;

  h2 {
    font-family: $dnv-display-medium;
    color: #0f204b;
    font-size: 1.2rem;
    padding: 0.7rem 1.1rem;
    text-transform: uppercase;
    background: #91ffb4;
  }

  a {
    text-align: center;
    display: block;
    width: 100%;
    cursor: pointer;
  }
  
  button {
    background: transparent;
    width: 100%;
    border: 0;

    &:active, &:hover, &:visited, &:focus {
      outline: none;
    }

    img {
      cursor: pointer;
    }

    &:disabled img {
      display: none;
    }

    &.bottom {
      margin-top: 1.5rem;
    }
  }

  &__intro {
    padding: 1.2rem 1.1rem 1.5rem;
    margin-bottom: -3rem;

    h3 {
      font-family: $dnv-display-medium;
      font-size: 1.2rem;
      color: #0f204b;
      padding-bottom: 1.1rem;
      text-transform: uppercase;
    }

    p {
      color: #0f204b;
      padding-right: 0.5rem;
    }
  }

  &__card {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 1.1rem;
    padding: 1.1rem 0;
    border-top: 2px solid $dnvgl-darkblue;

    &__image {
      width:95px;
      
      img {
        width: 75px;
        margin-right: 1.2rem;
      }
    }

    &__content {
      width: 100%;
    }

    button {
      margin-top:0.8rem;
      float: right;
    }

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      color: $dnvgl-lightblue;
    }

    a, p {
      color: $dnvgl-lightblue;
      text-decoration: none;
      text-align: left;

      span { 
        font-size: 6px;
        color: black;
        text-transform: uppercase;
      }
    }

    .time {
      color: $dnvgl-darkblue;
      font-weight: 600;
    }
  }
}


.event-container {
  height: 530px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  &__sessions {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    top: 0;
    transition: top 300ms ease-in;
  }
}