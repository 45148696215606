.HeaderTitle,
.HeaderTitle span {
  font-size: 3.3rem;
  font-family: $dnv-display-medium;
  line-height: 1.1;
  text-transform: uppercase;
  margin: 0 0 0.8rem 0;
  font-weight: normal;
}

.HeaderTitle {
  font-size: 3.3rem;
  font-family: $dnv-display-medium;
  line-height: 1.1;
  text-transform: uppercase;
  margin: 0 0 0.8rem 0;
  font-weight: normal;

  &--white {
    color: $white;
  }
  &--darkblue {
    color: $dnvgl-darkblue;
  }
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }

  span {
    &:first-of-type {
      font-size: 1.7rem;
    }
  }
}
