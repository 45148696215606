.Highlight {
  display: grid;
  grid-row: 2;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px 10px;
  position: relative;

  &__card {
    border-right: 1px solid white;
    padding-right: 0.505rem;
    color: $white;
    text-decoration: none;
    cursor: pointer;

    &:last-child {
      border-right: none;
    }

    img {
      width: 100%;
      margin-bottom: 1.7rem;
    }

    h3 {
      font-weight: 600;
      font-size: 1.17rem;
      text-transform: uppercase;
      padding-bottom: 1rem;
    }
    p {
      padding-right: 0.505rem;
      position: relative;
      bottom: 0;
    }
  }
}