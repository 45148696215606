.BreakoutRoomList {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 4rem 0 0;

  &:after {
    content: "";
    display: block;
    width: calc(50% - 0.5rem);
  }

  &__BreakoutRoom {
    background: #fff;
    width: calc(50% - 0.5rem);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    margin: 0 0 1rem;

    &__summary {
      padding: 0 1rem;
      h2,
      p {
        padding: 0;
        margin: 0;
        white-space: pre-line;
      }

      h2 {
        font-size: 1.1rem;
        font-weight: 700;
        color: $dnvgl-darkblue;
      }
      
      p {
        color: #000;
        margin-top: 1rem;
      }

      small {
        font-size: 0.85em;
        color: $dnvgl-steelgrey;
      }
    }

  }
}