.NavBar {
  background: $white;

  &__grid {
    @include grid-desktop;
    margin-top: 22px;
    margin-bottom: 22px;
    padding: 0;
    &__container {
      position: relative;
      grid-column: 1 / span 12;
      grid-row: 1;
    }
  } 

  .dnv-logo {
    display: inline-block;
    height: 40px;
    margin-left: -2350px;
    @media (min-width: $screen-xxl) {
      margin-left: -2423px;
    }
  }

  .ffts-logo {
    position: absolute;
    right: 50px;
    height: 50px;
    width: auto;
  }

  ul {
    display: inline;
    float: right;
    margin-right: 200px;
    margin-top: 14px;
    
  }
   
  li {
    display: inline;
    margin-left: 1.7rem;
    margin-right: 1.7rem;

    a {
      font-family: $arial;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      padding-bottom: 25px;
      color: $dnvgl-mediumblue;

      &:hover {
        border-bottom: 4px $dnvgl-mediumblue solid;
      }
      
    }
    
    a.active {
      border-bottom: 4px $dnvgl-mediumblue solid;
    }
  }
}