.Networking {
  background-image: url(../../assets/images/networking_23.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
  overflow-x: hidden;

  &__grid {
    @include grid-desktop;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    // padding-bottom: 0;

    &__container {
      grid-column: 1 / span 12;

      &__intro {
        @include body-copy;
        background: rgba(0, 71, 73, 0.8);
        font-size: 1.2rem;
        line-height: 1.7rem;
        margin: 2rem 0 3rem 0;
        text-align: center;
        padding: 2rem;

        p {
          max-width: 80%;
          margin: 0 auto 0.5rem;
          color: white;
        }

        a, a:visited {
          text-decoration: none;
          color: $dnvgl-mediumblue;
          font-size: inherit;
        }
      }
    }
  }

  &__session {
    &__timer {
      text-align: right;
      font-size: 20px;
      color: #fff;
      background: $color-success;
      line-height: 1.75;

      &--waiting {
        background: $color-warning;
        text-align: center;
      }

      &--ending {
        background: $color-error;
      }

      span {
        display: inline-block;
        border-radius: 3px;
        padding: 0 5px;
        font-weight: bold;
      }
    }
  }
}