.QuickLinkLandscape {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  width: 100%;
  background: rgba(152,143,134,0.6); //warmgrey 60%
  display: flex;
  cursor: pointer;

  .left {
    justify-items: flex-start;
    width: 45%;
    min-height: 169px;
    background-size: cover;
    background-position: center;
  }

  .right {
    justify-items: flex-end;
    padding: 0.6rem 1.4rem;
    color: $white;
    line-height: 1.15;
    font-weight: 400;
    text-decoration: none;
    width: 55%;

    h2 {
      text-transform: uppercase;
      font-size: 1.1rem;
      padding-bottom: 0.3rem;
    }
    
    p {
      text-decoration: none;
      font-size: 1rem;
    }
  }
}