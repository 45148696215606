.TeamSpace {
  background-image: url(../../assets/images/dnvffts_2_1920_g.jpg);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
  overflow-x: hidden;

  &__grid {
    @include grid-desktop;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    // padding-bottom: 0;

    &__title {
      grid-row: 1;
      grid-column: 1 / span 12;
      margin: 0;
    }

    &__room {
      grid-row: 2;
      grid-column: 1 / span 12;
      &--with-chat {
        grid-column: 1 / span 8;
      }
    }
  
    &__chat {
      grid-row: 2;
      grid-column: 9 / span 4;
    }    

    &__team-members {
      grid-row: 3;
      grid-column: 1 / span 12;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;

      &:after {
        content: "";
        display: block;
        width: calc(33% - 0.5rem);
      }
    }
  }
}