.QuickLink {
  background: white;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 260px;
  position: relative;
  cursor: pointer;

  &--orange {
    .QuickLink__front-content,
    .QuickLink__back-content {
      background: rgba(233,131,0,0.6);
    }
  }

  &--purple {
    .QuickLink__front-content,
    .QuickLink__back-content {
      background: rgba(110,80,145,0.6);
    }
  }

  &--blue {
    .QuickLink__front-content,
    .QuickLink__back-content {
      background: rgba(0,159,218,0.6);
    }
  }

  &--dark-blue {
    .QuickLink__front-content,
    .QuickLink__back-content {
      background: rgba(15,32,75,0.6);
    }
  }

  &--yellow {
    .QuickLink__front-content,
    .QuickLink__back-content {
      background: rgba(254,203,0,0.6);
    }
  }

  &__front-content {
    width: 100%;
    color: #0f204b;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.7rem 0.6rem 0.5rem;

    h2 {
      font-family: $dnv-display-medium;
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }

  &__back-content {
    color: #0f204b;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction:column;
    justify-content:space-around;

    p {
      margin: 2rem;
    }
  }
}