@import 'components/variables';
@import 'components/mixins';
@import 'components/fonts';
@import 'components/generic';
@import 'components/Button';
@import 'components/Video';
@import 'components/Login';
@import 'components/NavBar';
@import 'components/Home';
@import 'components/QuickLink';
@import 'components/EventCard';
@import 'components/Highlight';
@import 'components/Archive';
@import 'components/ArchiveFilter';
@import 'components/QuickLinkLandscape';
@import 'components/TeamSpace';
@import 'components/TeamMember';
@import 'components/LiveStream';
@import 'components/BreakoutRoom';
@import 'components/BreakoutRoomList';
@import 'components/Chat';
@import 'components/WherebyRoom';
@import 'components/Networking';

*,
html {
  font-size: 12px;
  font-family: $arial;
  font-weight: normal;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  @media (min-width: $screen-lg) {
    font-size: $base-font-size;
  }
}

body {
  @include body-copy;
  margin: 0;
  padding: 0;
  background: #fff;
}

.shadow {
  text-shadow: -0.05rem 0 1.4rem rgba(0, 0, 0, 0.7);
}
