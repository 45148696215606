// Fonts ------------------------------
$base-font-size: 18px;

$dnv-display-medium: "DNVDisplay Medium", Helvetica, Arial, sans-serif;
$arial: Arial, Helvetica, sans-serif;

// Colours ------------------------------
$dnvgl-darkblue: #0f204b; // sampled from photoshop design
$dnvgl-mediumblue: #003591; // sampled from photoshop design
$dnvgl-lightblue: #0f204b; // sampled from button on homepage
$dnvgl-warmgrey: #988f86; // sampled from placeholder text (.psd)
$dnvgl-lightgrey: #68747b; //sampled from homepage intro text (.psd)
$dnvgl-steelgrey: #d4d2cd; //Sampled from the archive subnav (.psd)
$dnvgl-midgrey: #bbb3a7;  // Sampled from archive tite container (.psd)
$dnvgl-smoothgrey: #c3baaf; // Sampled from the back button  archive item (.psd)
$dnvgl-green: #91ffb4; // sampled from login button (.psd)
$dnvgl-red: #c4262e; // From client brand guidelines secondary palette
$white: #FFFFFF;
$black: #000000;

$color-error:             $dnvgl-red;
$color-warning:           #FFBA00;
$color-success:           $dnvgl-mediumblue;


// Grid ------------------------------
$grid-column-count-desktop: 12;
$grid-column-gap-desktop: 20px;
$grid-row-gap-desktop: 20px;
$grid-column-gutter-desktop: 20px;


//Media queries ------------------------------
$screen-xxs: 374px;
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

// Website config ------------------------------
$max-page-width: 1280px;