.TeamMember {
  background: rgba(144,134,123,0.6);
  width: calc(33% - 0.5rem);
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  margin: 0 0 1rem;

  &__image {
    display: none;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    @media (min-width: $screen-lg) {
      display: block;
    }
  }

  &__summary {
    padding: 0 1rem;
    color: $white;

    &__name,
    &__role,
    &__location {
      padding: 0;
      margin: 0;
    }

    &__name {
      font-size: 1.1rem;
    }
    
    &__location {
      color: $dnvgl-steelgrey;
    }
  }
}