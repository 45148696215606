.LiveStream {
  background-image: url(../../assets/images/live_stage_23.jpg);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
  overflow-x: hidden;

  &__grid {
    @include grid-desktop;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &__title {
    grid-column: 1 / span 12;
    grid-row: 1;
  }

  &__video {
    grid-column: 1 / span 12;
    grid-row: 2;

    &__embed {
      padding: 56.25% 0 0 0;
      position: relative;
    }

    &__intro {
      color: $white;
      background: rgba(0, 0, 0, 0.6);
      padding: 1rem 1rem 4rem 1rem;
      min-height: 320px;

      &__title {
        font-size: 1.8rem;
        font-family: $dnv-display-medium;
        line-height: 1.5;
        text-transform: uppercase;
        margin: 0 0 0.5rem 0;
        font-weight: normal;
      }

      &__description {
        white-space: pre-line;
        strong {
          font-weight: 700;
        }
      }
    }

    &--with-chat {
      grid-column: 1 / span 8;
    }

    &--with-chat &__intro {
      max-width: initial;
    }
    
  }

  &__chat {
    grid-row: 2;
    grid-column: 9 / span 4;
  }

  &__speakers {
    grid-column: 1 / span 12;
    grid-row: 3;
    position: relative;
    display: flex;
    margin-top: 4rem;
    flex-wrap: wrap;
    color: $white;

    &::before {
      content: "";
      position: absolute;
      left: -100vw;
      top: -35px;
      width: 200vw;
      height: 100%;
      padding: 0;
      padding-bottom: 4rem;
      margin: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 0;
    }

    .Speaker {
      flex-basis: 20%;
      z-index: 10;
      max-width: 275px;
      padding: 0 0.7rem;
      border-right: 1px solid white;
      margin-bottom: 2rem;

      &__biography {
        margin-top: 0.5rem;
      }

      //  &:last-child {
      //    border-right: none;
      //  }
      //
      //  &:first-child {
      //    padding-left: 0;
      //  }

      img {
        // border-radius: 50%;
        margin-bottom: 0.8rem;
        width: 100px;
        height: 100px;
      }
    }
  }
}
