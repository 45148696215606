.ArchiveFilter {
  @include grid-desktop;
  margin-top: 22px;
  margin-bottom: 22px;
  padding: 0;

  &__grid {
    grid-column: 1 / span 12;
    grid-row: 1;
    background: $dnvgl-steelgrey;
    margin-top: 0.3rem;
    margin-bottom: 1.1rem;

    ul {
      text-align: center;
      padding: 1rem 0 0.8rem 0;

      li {
        margin: 0 1.6rem;
        padding-bottom: 0.5rem;
        display: inline;
        font-size: 0.9rem;
        color: $dnvgl-darkblue;
        text-transform: uppercase;
        font-weight: 900;
        
        &:hover {
          border-bottom: 0.3rem $dnvgl-warmgrey solid;
        }
      }
    }
  }
}