.Chat {
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  &__title {
    color: $dnvgl-mediumblue;
    font-size: 1.2rem;
    font-family: $dnv-display-medium;
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
    font-weight: normal;
  }

  &__messages {
    max-height: 400px;
    overflow: scroll;
    padding-right: 0.5rem;
    flex-grow: 1;
    margin: 0 -0.5rem;
  }

  .Message {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.5rem;
    white-space: pre-line;
    word-break: break-word;

    img {
      width: 55px;
      height: 55px;
      flex: 0 0 55px;
      margin-right: 0.5rem;
      display: block;
    }

    &__text {
      color: $dnvgl-lightblue;
      font-size: 0.9rem;
      @include no-select;

      div {
        color: $dnvgl-darkblue;
        font-weight: 600;
        font-size: inherit;
      }

      time {
        display: block;
        color: $dnvgl-warmgrey;
        font-size: 0.85em;
      }

      a, a:visited {
        color: $color-success;
      }
    }

    &--pinned {
      background: rgba(255, 186, 0, 0.4);

      &--top {
        padding-right: 0.5rem;
        margin: 0 -0.5rem;
      }
    }
  }

  &__form {
    text-align: right;
    overflow: hidden;
    position: relative;

    textarea {
      width: 100%;
      height: 150px;
      display: block;
      border-radius: 0%;
      border: none;
      background: $white;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      line-height: 1.4;
      padding: 0.3rem;
      resize: none;

      &:focus { 
        outline: none;
      }
    }

    &__counter {
      color: $dnvgl-warmgrey;
      font-size: 0.75rem;
      float: left;

      &--exceeded {
        color: $dnvgl-red;
      }
    }

    .Button {
      &:disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    label.pin-message-option {
      display: block;
      position: absolute;
      bottom: 0;
      font-size: 16px;
      color: $dnvgl-red;

      input {
        margin-left: 10px;
      }
    }
  }
}