.Login {
  background-image: url(../../assets/images/dnvffts_1_1920_f.jpg);
  background-size: cover;
  background-position: center top;
  height: 100%;
  min-height: 100vh;
  padding-top: 5.5rem;

  &__grid {
    @include grid-desktop;
    &__container {
      grid-column: 2 / span 10;
      grid-row: 1;
      text-align: center;
    }
  } 

  .intro-para {
    font-family: $arial;
    font-size: 1.2rem;
    color: $white;
    line-height: 1.3;
    margin: 2rem 0 1.5rem 0;

    span {
      color: $white;
      font-family: $dnv-display-medium;
      font-size: 1.2rem;
    }
  }

  .smallprint-box {
    background: rgba(0,0,0,0.15);
    padding: 1rem 1rem 0 1rem;
    margin-top: 2rem;
    p {
      font-family: $arial;
      font-size: 1rem;
      color: $white;
      line-height: 1.3;
      padding: 0 0 1rem 0;

      span {
        color: $white;
        font-family: $dnv-display-medium;
        font-size: 1.2rem;
      }
    }
  }

  &__Form {
    &__Message {
      @include body-copy;
      font-size: 1.2rem;
      margin-top: 3rem;
      background: rgba($white, 0.8);
      padding: $grid-row-gap-desktop/2;

      .Login__Form--success & {
        color: $dnvgl-green;
      }

      .Login__Form--error & {
        color: $dnvgl-red;
      }
    }

    input {
      border: none;
      width: 100%;
      margin: $grid-row-gap-desktop 0;
      padding: 1.1rem 1.4rem;
      color: $black;
      font-size: 1.2rem;
      &::placeholder {
        color:$dnvgl-warmgrey;
        opacity: 0.62;
      }
    }
  }
}