.Archive {
  background-image: url(../../assets/images/dnvffts_2_1920_g.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;

  &__grid {
    @include grid-desktop;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    padding-bottom: 0;

    &__container {
      grid-column: 1 / span 12;
      grid-row: 1;
      

      &__tiles {
        width: 100%;
        margin-top: 1.5rem;

        &--columns {
          width: 100%;
          column-count: 2;
          margin-top: 4rem; //  Zero when filter added back in <ArchiveFilter />
          padding: 1rem 1rem 0 1rem;

          & > * {
            display: inline-block;
            width: 100%;
            margin-bottom: 1rem;
          }
        }

        .QuickLink {
          width: 100%;
          padding: 1rem;
          min-height: 360px; 
          -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
          break-inside: avoid;
          margin-bottom: -0.3rem;
        }

        &__single-tile {
          @include grid-desktop;
          padding: 1rem 1rem 0 1rem;
          color: #fff;
          line-height: 1.3;

          h3 {
            font-size: 1.2rem;
            margin: 1rem 0 0 0;
            
            a, a:visited {
              font-size: inherit;
              font-weight: inherit;
            }
          }

          h2 {
            font-size: 2rem;
            text-transform: uppercase;
            line-height: 1.1;
            padding-bottom: 1.75rem;
          }

          p {
            color: #fff;
            padding-bottom: 0.5rem;
          }

          a {
            color: #fff;
            font-family: $dnv-display-medium;
            font-weight: 700;
          }

          ul {
            li {
              list-style-position: inside;
              text-indent: -1.5em;
              margin-left: 1.5rem;
              padding-bottom: 0.5rem;
            }
          }

          .video-only {
            grid-column: 1 / span 12;
            grid-row: 1;
          }
          
          .video-text {
            grid-column: 1 / span 6;
            grid-row: 1;
            margin-bottom: 1.5rem;
          }

          &__video {
            &__intro {
              position: relative;
              top: 3.5rem;
              color: #fff;
              font-family: $dnv-display-medium;
            }

            .embed-container { 
              position: relative; 
              padding-bottom: 56.25%; 
              height: 0; 
              overflow: hidden; 
              max-width: 100%; 
            } 

            .embed-container iframe, 
            .embed-container object, 
            .embed-container embed { 
              position: absolute; 
              top: 0; 
              left: 0; 
              width: 100%; 
              height: 100%; 
            }
          }

          &__image {
            grid-column: 1 / span 6;
            grid-row: 1;
            margin-bottom: 1rem;

            img {
              width: 100%;
              padding: 0;
              margin: 0;
            }
          }

          &__content {
            grid-column: 7 / span 6;
            grid-row: 1;
            margin-bottom: 0.7rem;
          }

          &__full-page {
            grid-column: 3 / span 8;
            grid-row: 1;
            margin-bottom: 0.7rem;
          }
        }
      }
    }
  }

  .Button-back {
    padding: 0.5rem 1.2rem;
    font-size: 0.7rem;
    color: $white;
    border: none;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin: 1rem 1rem 0 1rem;
    text-decoration: none;
    
    &--grey {
      background: $dnvgl-smoothgrey;
    }

    img {
      padding-right: 1.4rem;
    }
  }
}
