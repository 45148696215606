@mixin grid-desktop($columns: $grid-column-count-desktop) {
  max-width: $max-page-width;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  grid-column-gap: $grid-column-gap-desktop;
  grid-row-gap: $grid-row-gap-desktop;
  @if ($columns == $grid-column-count-desktop) {
    padding: $grid-row-gap-desktop $grid-column-gutter-desktop;
  }
}

@mixin body-copy($color: $black) {
  color: $color;
  font-family: $arial;
  font-size: 1rem;
  line-height: 1.33rem;
}

@mixin no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}